import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { LocaleContext } from "./layout"
import locales from "../../config/i18n"

const LocalizedLink = ({ to, sub, ...props }) => {
  const { locale } = React.useContext(LocaleContext)
  const isIndex = to === "/" || to === "homepage"
  const path = locales[locale].default
    ? `${sub ? sub + "/" : ""}${isIndex ? "" : to}`
    : `${locales[locale].path}/${sub ? sub + "/" : ""}${isIndex ? "" : to}`

  return <Link {...props} to={"/" + path} />
}

export default LocalizedLink

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
}
