import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { css, keyframes } from "@emotion/react"

import { Link } from "gatsby"
import LocalizedLink from "./localizedLink"

import { LocaleContext } from "./layout"
import locales from "../../config/i18n"

import { OutboundLink } from "gatsby-plugin-google-gtag"

const alternateUid = (lang, alternateLanguages) => {
  const alternateLanguage = alternateLanguages.filter(
    alternateLang => alternateLang.lang === lang
  )
  return alternateLanguage[0].uid
}

const Header = ({ alternateLanguages, scrollPos, isDark }) => {
  const { locale, i18n } = React.useContext(LocaleContext)
  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const data = useStaticQuery(
    graphql`
      query {
        allPrismicMenu {
          edges {
            node {
              lang
              data {
                menu_item {
                  link {
                    lang
                    uid
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return (
    <>
      <header className="mx-auto">
        <div className="  fixed w-full top-0 z-30 ">
          <div
            className={`w-full py-3 ${
              scrollPos > 160 || isMenuOpened
                ? `${
                    !isDark
                      ? "bg-white/70 border-gray-200"
                      : "bg-medallo-purpleDark/80 border-gray-900"
                  } backdrop-blur border-solid border-b-[1px] w-full  `
                : ""
            }`}
          >
            <div className="container relative w-full mx-auto max-w-5xl">
              <nav
                className={`left-0 flex  justify-between m-0  ${
                  isMenuOpened
                    ? "flex-col items-start"
                    : "flex-row  items-center"
                } md:flex-row`}
              >
                <button
                  onClick={() => setIsMenuOpened(!isMenuOpened)}
                  className={`px-2 py-5 mx-5 text-comet  font-sans font-bold uppercase text-sm transition-colors hover:text-gray-500 md:hidden ${
                    isMenuOpened ? "text-left" : ""
                  }`}
                >
                  {!isMenuOpened ? "Menu" : "x"}
                </button>
                <div
                  className={`flex ${
                    isMenuOpened
                      ? "flex-col w-full "
                      : "flex-row md:w-full md:max-w-xl"
                  } md:flex-row`}
                >
                  {data.allPrismicMenu.edges
                    .filter(edge => edge.node.lang === locale)
                    .map(menu =>
                      menu.node.data.menu_item.map(item => (
                        <LocalizedLink
                          key={item.link.uid}
                          to={item.link.uid}
                          onClick={() => setIsMenuOpened(false)}
                          css={item.link.uid === "starter" && cssButtonGradient}
                          className={`px-2 py-5  text-comet font-sans font-bold uppercase text-sm transition-colors hover:text-gray-500 md:block ${
                            isMenuOpened
                              ? "block  text-center w-full  "
                              : "hidden  md:mx-2 md:w-full md:flex-1 md:text-center"
                          }
                          ${
                            item.link.uid === "starter"
                              ? "md:py-2 md:my-3  md:rounded-md md:bg-mulberry md:text-white hover:md:text-polar"
                              : ""
                          }`}
                        >
                          {item.title.text}
                        </LocalizedLink>
                      ))
                    )}
                </div>
                <div
                  className={`flex flex-row ${
                    isMenuOpened ? " w-100 justify-center pb-10 mx-auto" : ""
                  }`}
                >
                  <div className="bg-white mx-3 flex rounded-full border-solid border border-gray-300">
                    {Object.keys(i18n).map(lang => {
                      const path = locales[lang].default
                        ? ""
                        : `${locales[lang].path}/`
                      return (
                        <div key={lang}>
                          {lang !== locale && (
                            <>
                              {alternateLanguages && (
                                <Link
                                  to={
                                    "/" +
                                    path +
                                    alternateUid(lang, alternateLanguages)
                                  }
                                >
                                  <div className="mx-1 my-1 px-4 py-2 rounded-full text-gray-300 font-sans font-bold uppercase text-sm transition-colors hover:bg-gray-300 hover:text-gray-500">
                                    {locales[lang].path}
                                  </div>
                                </Link>
                              )}
                              {!alternateLanguages && (
                                <Link to={"/" + path}>
                                  <div className="mx-1 my-1 px-4 py-2 rounded-full text-gray-300 font-sans font-bold uppercase text-sm transition-colors hover:bg-gray-300 hover:text-gray-500">
                                    {locales[lang].path}
                                  </div>
                                </Link>
                              )}
                            </>
                          )}
                          {lang === locale && (
                            <div
                              className={`${
                                !isDark
                                  ? "bg-mulberry"
                                  : "bg-medallo-purpleLight"
                              } mx-1 my-1 px-4 py-2 rounded-full text-white font-sans font-bold uppercase text-sm`}
                            >
                              {locales[lang].path}
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>

                  <div
                    className={` ${
                      !isDark ? "bg-mulberry" : "bg-medallo-purpleLight"
                    }  mx-3  flex flex-grow-0  rounded-full border-solid border border-gray-300 text-white text-center font-sans font-bold uppercase text-sm transition-colors `}
                  >
                    <OutboundLink
                      href="mailto:josselin.caer@gmail.com?subject=Contact%20From%josselin.pro"
                      className={`flex-1  px-4 py-2 mx-1 self-center rounded-full transition-colors font-sans font-bold uppercase text-sm ${
                        !isDark
                          ? "hover:bg-mulberry"
                          : "hover:bg-medallo-purpleDark"
                      }  hover:text-white `}
                    >
                      Contact
                    </OutboundLink>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

const cssAnmim = keyframes`
0%{background-position:0% 50%}
50%{background-position:100% 50%}
100%{background-position:0% 50%}
`

const cssButtonGradient = css`
  background: linear-gradient(270deg, #f4c82b, #ff6ad5);
  background-size: 400% 400%;
  animation: ${cssAnmim} 3s ease infinite;
`

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
