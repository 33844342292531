module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Josselin Caer - Freelance developer", // Navigation and Site Title
  titleAlt: "Get a Gatsby static website", // Title for JSONLD
  description: "A freelancer for your Gatsby website. Josselin Caer",
  url: process.env.GATSBY_WEBSITE_URL, // Domain of your site. No trailing slash!
  ogLanguage: "en_US", // Facebook Language

  // JSONLD / Manifest
  favicon: "static/images/favicon.png", // Used for manifest favicon generation
  shortName: "Josselin Caer", // shortname for manifest. MUST be shorter than 12 characters
  author: "Josselin CAER", // Author for schemaORGJSONLD
  themeColor: "#FCF3EF",
  backgroundColor: "#FCF3EF",
  logo: "static/images/favicon.png", // Used for manifest favicon generation

  twitter: "", // Twitter Username
  facebook: "", // Facebook Site Name
  googleAnalyticsID: "G-79RCD668EC",
  googleTagManager: "GTM-K2X8FD4",
}
