import PropTypes from "prop-types"
import React from "react"
import RoundedCard from "./roundedCard"
import { useStaticQuery, graphql } from "gatsby"

const FormEmail = ({ locale }) => {
  const [formSent, setFormsent] = React.useState(false)

  const data = useStaticQuery(
    graphql`
      query {
        allPrismicGlobal {
          edges {
            node {
              ...PrismicGlobalFragment
            }
          }
        }
      }
    `
  )
  if (!locale) {
    return <></>
  }
  const filterLang = (data, locale) => {
    return data.allPrismicGlobal.edges.filter(d => d.node.lang === locale)[0]
  }
  const dataLang = filterLang(data, locale)

  const handleSubmit = event => {
    event.preventDefault()

    const myForm = event.target
    const formData = new FormData(myForm)

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => setFormsent(true))
      .catch(error => alert(error))
  }

  return (
    <div className="fixed bottom-0  z-10 w-full pt-5 bg-gradient-linen md:bottom-5 md:w-80 md:right-5 md:bg-none">
      {!formSent && (
        <form
          data-netlify="true"
          name="fixedForm"
          method="post"
          action={"/?formSent=true"}
          onSubmit={handleSubmit}
          className={` bg-white flex items-end relative w-11/12 mx-auto p-5 rounded-2xl rounded-b-none shadow  md:w-80 `}
        >
          <input type="hidden" name="form-name" value="fixedForm"></input>
          <div className="w-64 mx-auto md:w-full">
            <label className="text-xl text-center font-sans font-bold  text-comet ">
              {dataLang.node.data.form_email_title}{" "}
              <input
                type="email"
                name="email"
                className="font-normal text-sm border-[1px] px-3 py-2 border-danube mt-4 h-11 w-52"
                placeholder={dataLang.node.data.form_email_placeholder}
              />
            </label>
            <button className="bg-danube text-white font-bold px-2 py-1 rounded-lg ml-2 h-11">
              OK
            </button>
          </div>
        </form>
      )}
      {formSent && (
        <div className="bg-white flex items-end relative w-11/12 mx-auto p-5 rounded-2xl rounded-b-none shadow ">
          <p>{dataLang.node.data.form_email_sent}</p>
        </div>
      )}
    </div>
  )
}

FormEmail.propTypes = {
  // list: PropTypes.object,
}

export default FormEmail
