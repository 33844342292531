/* eslint react/prop-types: 0, react/display-name: 0 */
import React from "react"

import { Layout } from "./src/components/layout"
// import browserLang from "browser-lang"
// import { navigate } from "gatsby"

// const locales = require("./config/i18n")

const wrapPageElement = ({ element, props }) => {
  /*if (typeof window !== "undefined") {
    const browserUserLang = browserLang()
      .toLowerCase()
      .slice(0, 2)

    // if user doesn't have a lang defined in localstorage yet
    if (!window.sessionStorage.getItem("userPreferedLang")) {
      Object.keys(locales).map(lang => {
        const localeLang = lang.slice(0, 2)
        let defaultAppLang = undefined

        window.sessionStorage.setItem("userPreferedLang", browserUserLang)

        if (locales[lang].default === true) {
          // console.log("setting default")
          defaultAppLang = locales[lang].path
        }

        // if browserLang is available in locales -> redirect
        if (localeLang === browserUserLang) {
          // console.log(
          //   "localeLang match browserlang " + localeLang + " " + defaultAppLang
          // )
          // if equals default redirect to "/" (/defaultLang do not exist)
          // TODO redirect to same page
          localeLang === defaultAppLang
            ? navigate("/")
            : navigate("/" + localeLang)
        }
        return true
      })
      // browserUserLang do not match any known lang, redirect to default
      navigate("/")
    }
  }
  */

  return <Layout {...props}>{element}</Layout>
}

export default wrapPageElement
