exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-casestudy-js": () => import("./../../../src/templates/casestudy.js" /* webpackChunkName: "component---src-templates-casestudy-js" */),
  "component---src-templates-medallo-js": () => import("./../../../src/templates/medallo.js" /* webpackChunkName: "component---src-templates-medallo-js" */),
  "component---src-templates-medallo-sub-cms-js": () => import("./../../../src/templates/medallo_sub_cms.js" /* webpackChunkName: "component---src-templates-medallo-sub-cms-js" */),
  "component---src-templates-works-js": () => import("./../../../src/templates/works.js" /* webpackChunkName: "component---src-templates-works-js" */)
}

