export const colors = {
  primary: "#663399",
  primary_darker: "#52297B",
  primary_rgb: "102, 51, 153",
  primary_darker_rgb: "62, 21, 104",

  secondary: "#E8FF57",
  secondary_darker: "#D1E83E",
  secondary_rgb: "232, 255, 87",
  secondary_darker_rgb: "168, 192, 20",

  white: "#ffffff",
  lightgrey: "#eeeeee",
  text: "#3C3C3C",
}

export const fonts = {
  font_primary: "Playfair Display",
}

const breakpoints = [768, 1023, 1215, 1408]
export const mq = breakpoints.map(bp => `@media (min-width: ${bp}px)`)
