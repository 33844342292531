import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"
import i18n from "../../config/i18n"
import { fonts } from "../styles/styles"

import Header from "./header"
import FormEmail from "./formEmail"
import "slick-carousel/slick/slick.css"
// import "slick-carousel/slick/slick-theme.css"

const LocaleContext = React.createContext()
let darkBackground = false
const Layout = ({
  children,
  pageContext: { locale, alternateLanguages, isDark },
}) => {
  const [scroll, setScroll] = useState(null)
  darkBackground = isDark
  useEffect(() => {
    const handleScroll = event => {
      setScroll(Math.floor(window.scrollY))
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <>
      <LocaleContext.Provider value={{ locale, i18n }}>
        <Header
          alternateLanguages={alternateLanguages}
          scrollPos={scroll}
          isDark={isDark}
        />
        <main css={main} style={isDark ? { background: "#240053" } : {}}>
          {children}
        </main>
        <FormEmail locale={locale} />
      </LocaleContext.Provider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout, LocaleContext }

const main = css`
  & {
    letter-spacing: -1px;
    word-spacing: .25rem;
    font-family: '${fonts.font_primary}';
  }

`
