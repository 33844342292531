const website = require("./website")

module.exports = {
  "fr-fr": {
    path: "fr",
    locale: "fr-fr",
    siteLanguage: "fr",
    ogLang: "fr_FR",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: "Un freelance pour votre site Gatsby. Josselin Caer",
  },
  "en-gb": {
    default: true,
    path: "en",
    locale: "en-gb",
    siteLanguage: "en",
    ogLang: "en_US",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
  },
}
