import PropTypes from "prop-types"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { LocaleContext } from "./layout"

import LocalizedLink from "./localizedLink"

const RoundedCard = ({ title, uid, className }) => {
  const { locale } = React.useContext(LocaleContext)
  const data = useStaticQuery(
    graphql`
      query {
        allPrismicGlobal {
          edges {
            node {
              lang
              data {
                read_blogpost
              }
            }
          }
        }
      }
    `
  )
  const globals = data.allPrismicGlobal.edges.filter(
    global => global.node.lang === locale
  )

  return (
    <>
      <article
        className={`bg-white m-10 p-5 rounded-2xl shadow max-w-md  ${className}`}
      >
        <h5 className="text-xl text-center font-sans font-bold  text-comet">
          {title}
        </h5>
        <LocalizedLink
          className="block text-center font-sans font-bold uppercase bg-polar text-danube mt-5 w-full text-sm p-2 rounded-lg transition-colors hover:bg-danube hover:text-white"
          to={uid}
          sub={"blog"}
        >
          {globals[0].node.data.read_blogpost}
        </LocalizedLink>
      </article>
    </>
  )
}

RoundedCard.propTypes = {
  list: PropTypes.object,
}

export default RoundedCard
